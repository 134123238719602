import React from "react";
import styled, {createGlobalStyle} from "styled-components";
import {ErrorPageIcon} from "@ui/components/IconsSVGs";

function ErrorPage({isKiosk = false}) {
  const onClickHomePage = () => {
    if (isKiosk) {
      window.location.href = "/" + window.location.pathname.split("/").filter(Boolean)[0];
    } else {
      window.location.href = "/";
    }
  };
  return (
    <Container>
      <GlobalStyles />
      <InnerContainer>
        <ErrorPageIcon /> {/* Use an appropriate icon here */}
        <Title>We can't seem to find the page you're looking for.</Title>
        <Subtitle>
          Sorry, we couldn't find what you were looking for or the page no longer exists.
          Perhaps you can return back to homepage and see if you can find what you are
          looking for.
        </Subtitle>
        <ButtonContainer>
          <Button onClick={onClickHomePage}>Homepage</Button>
        </ButtonContainer>
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  max-width: 750px;
  padding: 40px;
  box-shadow: 0 0 22px 2px #00000026;
  border-radius: 6px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 20px);
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  color: #000;
  margin: 20px 0;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #9e9e9e;
  margin-bottom: 40px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
`;

const GlobalStyles = createGlobalStyle`
  body {
    font-family: Seravek, "Gill Sans Nova", Ubuntu, Calibri, "DejaVu Sans", source-sans-pro, sans-serif;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
`;

export default ErrorPage;

export async function isr_getServerSideProps({res}) {
  res.statusCode = 503;

  return {
    props: {}, // You can pass props to the page if needed
  };
}
