import React from "react";
import ErrorPage from "@ui/components/ErrorPage/ErrorPage";

export default function FourOhFour(props) {
  return <ErrorPage isKiosk={props.isKiosk} />;
}

export async function getStaticProps() {
  return {
    props: {
      isKiosk: process.env.REACT_APP_MODE === "kiosk",
    },
  };
}
